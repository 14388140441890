<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-1 ps-0 pe-0">
        <el-menu default-active="1" :collapse="true" class="pt-4 pb-4">
          <div class="logo">
            <img
              class="logo-img img-fluid"
              src="@/assets/img/logo-batters(mob).png"
              alt="logo BATTERS"
            />
          </div>
          <el-menu-item
            v-for="(item, index) in items"
            :key="index"
            :index="index.toString()"
          >
            <router-link :to="{ name: item.route }">
              <el-badge
                :value="pending[item.collection]"
                v-if="item.show_badge && pending[item.collection] > 0"
              >
                <i class="me-2 bi" :class="item.icon" />
              </el-badge>
              <i class="me-2 bi" :class="item.icon" v-else />
            </router-link>
            <template #title>
              <router-link :to="{ name: item.route }" class="fs-16">
                <i class="me-2 bi" :class="item.icon" />
                <span>{{ item.label }}</span>
              </router-link>
            </template>
          </el-menu-item>
          <el-menu-item class="menu-item__logout">
            <a href="#" @click="logout"><i class="bi bi-power me-2"></i></a>
            <template #title>
              <a href="#" @click="logout" class="fs-16">
                <i class="bi bi-power me-2"></i>
                <span>Logout</span>
              </a>
            </template>
          </el-menu-item>
        </el-menu>
      </div>

      <div class="col-11">
        <router-view />
      </div>
    </div>
  </div>
</template>

<script>
import Database from '@/utils/services/Database';
import Socket from '@/utils/services/Socket';

import menu from '@/utils/config/menu';

export default {
  name: 'AdminLayout',
  data() {
    return {
      items: menu,
      pending: {
        news: 0,
        library: 0,
      },
    };
  },
  async created() {
    await this.getData();
  },
  mounted() {
    const { socket } = Socket.initialize();
    socket.onAny(() => {
      this.getData();
    });
  },
  methods: {
    async getData() {
      const pending_news = await Database.getAll('news', {
        type: 'notifications',
      });
      const pending_library = await Database.getAll('library', {
        type: 'notifications',
      });
      this.pending = {
        news: pending_news,
        library: pending_library,
      };
    },
    async logout() {
      try {
        await this.$store.dispatch('logout');
        this.$router.push({ name: 'Home' });
      } catch (err) {
        console.error(err);
      }
    },
  },
};
</script>

<style lang="scss">
.menu-toggle {
  padding: 1rem;
  i {
    font-size: 30px;
  }
}

.el-menu {
  min-height: 90vh;
  i {
    font-size: 25px;
  }
  span {
    margin-left: 0.5rem;
  }
  .menu-item__logout {
    width: 4.7%;
    position: fixed;
    bottom: 10%;
  }
  .logo {
    display: flex;
    justify-content: center;
    padding-bottom: 2rem;
    margin-top: -1rem;
    .logo-img {
      max-width: 2rem;
    }
  }
}

.el-popper {
  a {
    span,
    i {
      color: $light !important;
    }
  }
}

.el-badge__content {
  transform: translateY(30%) translateX(60%) !important;
}
</style>
